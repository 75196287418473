import React, { ReactNode, useState, useEffect } from 'react'

import styles from './styles.module.scss'

interface PopoverProps {
  show?: boolean
  onShowChange?: (show: boolean) => void
  children?: ReactNode
  content?: ReactNode
  align?: 'left' | 'center' | 'right'
  width?: number
  hideOnClickOut?: boolean
}

const Popover = ({
  show = false,
  onShowChange,
  children,
  content,
  align = 'center',
  width = 120,
  hideOnClickOut = false,
}: PopoverProps) => {
  // States

  const [_show, setShow] = useState<boolean>(show)

  // Effects

  useEffect(() => {
    if (!hideOnClickOut) return

    const hide = () => {
      setShow(false)
    }

    if (_show) {
      window.addEventListener('click', hide)
    } else {
      window.removeEventListener('click', hide)
    }

    if (onShowChange) onShowChange(_show)
  }, [_show, hideOnClickOut, onShowChange])

  useEffect(() => {
    setShow(show)
  }, [show])

  // Rendering

  let popOverClassName = styles.popOver
  switch (align) {
    case 'left':
      popOverClassName += ` ${styles.left}`
      break
    case 'center':
      popOverClassName += ` ${styles.center}`
      break
    case 'right':
      popOverClassName += ` ${styles.right}`
      break
  }

  return (
    <div className={styles.holder}>
      <div
        className={popOverClassName}
        style={{
          opacity: _show ? 1 : 0,
          marginBottom: _show ? 8 : -5,
          pointerEvents: _show ? 'all' : 'none',
          left: align === 'center' ? -width / 2 : undefined,
          width,
        }}
        onClick={(e) => e.stopPropagation()}
      >
        { content }
      </div>
      { children }
    </div>
  )
}

export default Popover
