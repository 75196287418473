import { useState, useEffect, useCallback } from 'react'

/**
 * useWindowSize React custom hook
 * Usage:
 *    const { width, height } = useWindowSize();
 */
const useWindowSize = () => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(() => {
    return {
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isClient, window.innerWidth, window.innerHeight])

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(() => {
    if (!isClient) {
      return
    }

    const handleResize = () => {
      setWindowSize(getSize())
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [getSize, isClient])

  return windowSize
}

export default useWindowSize
