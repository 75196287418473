import React, { useState, useEffect, ReactNode } from 'react'

import styles from './styles.module.scss'

import xIconSrc from '../../assets/images/x-icon.svg'

interface ModalProps {
  title?: string
  children?: ReactNode
  show?: boolean
  onVisibilityChange?: (visible: boolean) => void
}

const Modal = ({
  title,
  children,
  show = false,
  onVisibilityChange,
}: ModalProps) => {
  // States

  const [_show, setShow] = useState(show)

  // Effects

  useEffect(() => {
    if (_show !== show) setShow(show)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show])

  useEffect(() => {
    if (onVisibilityChange) onVisibilityChange(_show)
  }, [_show, onVisibilityChange])

  // Handlers

  const onHide = () => {
    setShow(false)
  }

  // Rendering

  return (
    <div
      className={`${styles.holder} ${_show ? styles.visible : ''}`}
      onClick={onHide}
    >
      <div className={styles.modal} onClick={(e) => e.stopPropagation()}>
        <div className={styles.title}>
          <h1>{ title }</h1>
          <button onClick={onHide}>
            <img src={xIconSrc} alt="X" />
          </button>
        </div>
        { children }
      </div>
    </div>
  )
}

export default Modal
