import React from 'react'
import styles from './styles.module.scss'

import spinnerIconSrc from '../../assets/images/spinner-icon.svg'

const Loader = () => {
  return (
    <img src={spinnerIconSrc} alt="Loading..." className={styles.spinorama} />
  )
}

export default Loader
