import React, { useEffect, useState } from 'react'

import styles from './styles.module.scss'

import checkIconSrc from '../../assets/images/check-icon.svg'

interface CheckboxProps {
  text?: string
  checked?: boolean
  onChange?: (value: boolean) => void
}

const Checkbox = ({ text, checked = false, onChange }: CheckboxProps) => {
  // States

  const [_checked, setChecked] = useState(checked)

  // Effects

  useEffect(() => {
    if (onChange) onChange(_checked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_checked])

  useEffect(() => {
    if (checked !== _checked) setChecked(checked)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checked])

  // Handlers

  const onClickHolder = () => {
    setChecked(!_checked)
  }

  // Rendering

  return (
    <div
      className={`${styles.holder} ${_checked ? styles.checked : ''}`}
      onClick={onClickHolder}
    >
      <button className={styles.checkbox}>
        <img src={checkIconSrc} alt="✓" />
      </button>
      { text && <label>{ text }</label> }
    </div>
  )
}

export default Checkbox
