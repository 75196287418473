/* eslint-disable @typescript-eslint/no-floating-promises */
import React, {
  ChangeEvent,
  DragEvent,
  ReactNode,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '..'

import uploadIconSrc from '../../assets/images/upload-icon.svg'

import styles from './styles.module.scss'

const MAX_FILE_SIZE = 1024 * 1024 * 20 // 20MB

interface FileUploadProps {
  buttonText: string
  allowedFileTypes: string[]
  instructions?: string
  children?: ReactNode
  file?: File
  showName?: boolean
  error?: string
  draggingClassName?: string
  onFileSelect?: (file: File) => void
}

const ImageUpload = ({
  buttonText,
  allowedFileTypes,
  instructions,
  children,
  file,
  showName = false,
  error,
  draggingClassName,
  onFileSelect,
}: FileUploadProps) => {
  const { t } = useTranslation('general')

  // States

  const [dragging, setDragging] = useState(false)
  const [_error, setError] = useState<string | undefined>(error)
  const [_file, setFile] = useState<File | undefined>(file)

  // Effects

  useEffect(() => {
    if (_error !== error) setError(error)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [error])

  useEffect(() => {
    console.log(file)
    if (_file !== file) setFile(file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file])

  useEffect(() => {
    if (!_file) return

    setError(undefined)
    if (onFileSelect) onFileSelect(_file)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_file])

  // Handlers

  const onDragEnter = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDragging(true)
  }

  const onDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDragging(false)
  }

  const onDrop = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault()
    event.stopPropagation()
    setDragging(false)

    if (event.dataTransfer.files.length === 1) { handleFilesUpdate(event.dataTransfer.files[0]) }
  }

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (!event || !event.target || !event.target.files) return
    if (event.target.files.length === 1) { handleFilesUpdate(event.target.files[0]) }
  }

  const handleFilesUpdate = async (file: File) => {
    if (!allowedFileTypes.includes(file.type)) {
      setError(t('invalidFileType'))
      return
    }
    if (file.size > MAX_FILE_SIZE) {
      setError(t('fileTooLarget'))
      return
    }

    setFile(file)
  }

  // Rendering

  let labelText = instructions
  if (_file && showName) labelText = _file.name
  if (_error) labelText = _error

  return (
    <div
      className={`${styles.holder} ${
        draggingClassName && dragging ? draggingClassName : ''
      }`}
      onDragEnter={onDragEnter}
      onDragLeave={onDragLeave}
      onDrop={onDrop}
      onChange={onChange}
    >
      <input
        type="file"
        accept={allowedFileTypes.join(', ')}
        onClick={(event) => {
          // @ts-expect-error
          event.target.value = null
        }}
      />
      { children }
      <div>
        <Button icon={uploadIconSrc}>{ buttonText }</Button>
        <label className={_error ? styles.hasError : undefined}>
          { labelText }
        </label>
      </div>
    </div>
  )
}

export default ImageUpload
