/* eslint-disable @typescript-eslint/no-misused-promises */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { LANGUAGES } from '../../translations'

import styles from './styles.module.scss'

// Helpers

const LanguagePicker = () => {
  const { i18n } = useTranslation()
  const { language } = i18n

  // Rendering

  const renderLanguageButton = (lang: string) => {
    const active = language.includes(lang)

    return (
      <button
        className={active ? styles.active : undefined}
        onClick={async () => await i18n.changeLanguage(lang)}
      >
        { lang }
      </button>
    )
  }

  return (
    <div className={styles.picker}>{ LANGUAGES.map(renderLanguageButton) }</div>
  )
}

export default LanguagePicker
