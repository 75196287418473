import React, { useState, useEffect } from 'react'
import { Size, Point, Area } from 'react-easy-crop/types'
import { useTranslation } from 'react-i18next'
import Cropper from 'react-easy-crop'
import ReactSlider from 'react-slider'
import { Button } from '..'
import { cropImage } from '../../helpers/image'

import styles from './styles.module.scss'

interface ImageCropperProps {
  url?: string
  cropShape?: 'rect' | 'round'
  finalSize?: Size
  onCropDone?: (file: File | undefined) => void
}

const ImageCropper = ({
  url,
  finalSize = undefined,
  cropShape = 'rect',
  onCropDone,
}: ImageCropperProps) => {
  const { t } = useTranslation('general')

  // States

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<
  Area | undefined
  >()
  const [isLoading, setIsLoading] = useState(false)

  // Effects

  useEffect(() => {
    setCrop({ x: 0, y: 0 })
    setZoom(1)
  }, [url])

  // Handlers

  const onCropComplete = (_: Area, croppedAreaPixels: Area) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }

  const onClickDone = async () => {
    if (!url || !croppedAreaPixels) return

    setIsLoading(true)

    const file = await cropImage(
      url,
      croppedAreaPixels,
      finalSize,
      cropShape === 'round',
    )

    setIsLoading(false)

    if (onCropDone) onCropDone(file)
  }

  // Rendering

  const aspect = finalSize ? finalSize.width / finalSize.height : 1

  return (
    <div className={styles.holder}>
      <p>{ t('resizeInstructions') }</p>
      <div className={styles.cropperHolder}>
        <Cropper
          image={url}
          crop={crop}
          zoom={zoom}
          aspect={aspect}
          cropShape={cropShape}
          onCropChange={setCrop}
          onCropComplete={onCropComplete}
          onZoomChange={setZoom}
        />
      </div>
      <ReactSlider
        className={styles.slider}
        trackClassName={styles.track}
        thumbClassName={styles.thumb}
        thumbActiveClassName={styles.active}
        min={1}
        max={3}
        step={0.1}
        value={zoom}
        onChange={setZoom}
      />
      <Button loading={isLoading} onClick={onClickDone}>
        { t('done') }
      </Button>
    </div>
  )
}

export default ImageCropper
