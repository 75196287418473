/* eslint-disable @typescript-eslint/no-floating-promises */
import React, { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { Button, Field } from '../../../components'
import { EMAIL_REGEX } from '../../../helpers/regex'
import { requestProUserLinkEmail } from '../../../helpers/api'

import styles from './styles.module.scss'

interface GetLinkFormProps {
  email?: string
}

const GetLinkForm = ({ email = '' }: GetLinkFormProps) => {
  const { t } = useTranslation('signup')
  const { t: errors } = useTranslation('general')

  // States

  const [_email, setEmail] = useState<string>(email)
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Errors

  const emailError = !EMAIL_REGEX.test(_email)
    ? errors('invalidEmail')
    : undefined

  // Handlers

  const onSubmit = async () => {
    setIsSubmitted(true)
    if (emailError) {
      toast.error(emailError)
      return
    }

    setIsLoading(true)

    try {
      setIsLoading(true)

      await requestProUserLinkEmail(_email)

      toast.success(t('linkSent'))
      setEmail('')
      setIsSubmitted(false)
    } catch (e) {
      toast.error(errors('serverError'))
    } finally {
      setIsLoading(false)
    }
  }

  // Rendering

  return (
    <div className={styles.holder}>
      <p>{ t('getMyLinkInstructions') }</p>
      <Field
        className={styles.field}
        value={_email}
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          setEmail(e.target.value)}
        onKeyDown={(e: KeyboardEvent) => {
          if (e.key === 'Enter') {
            onSubmit()
          }
        }}
        placeholder={t('email')}
        error={isSubmitted ? emailError : undefined}
      />
      <Button
        disabled={isSubmitted && emailError !== undefined}
        onClick={onSubmit}
        loading={isLoading}
      >
        { t('send') }
      </Button>
    </div>
  )
}

export default GetLinkForm
