/* eslint-disable @typescript-eslint/no-floating-promises */
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import enTranslation from './en'
import frTranslation from './fr'

export const LANGUAGES = ['fr', 'en']

const resources = {
  en: { ...enTranslation },
  fr: { ...frTranslation },
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en',
    defaultNS: 'default',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
