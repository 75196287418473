import React, { FC, useState } from 'react'
import { Popover } from '..'

import styles from './styles.module.scss'

import infoIconSrc from '../../assets/images/info-icon.svg'

interface FieldProps {
  infoText?: string
  textarea?: Boolean
  icon?: string
  error?: string
}

const Field: FC<FieldProps & any> = (props) => {
  const { infoText, textarea, icon, error, className } = props

  // States

  const [isHoveringInfo, setIsHoveringInfo] = useState(false)

  // Rendering

  const inputStyle = {
    paddingRight: infoText ? 35 : undefined,
    paddingLeft: icon ? 60 : undefined,
  }

  return (
    <div
      className={`${styles.holder} ${error ? styles.hasError : ''} ${
        String(className)
      }`}
    >
      <div className={styles.wrapper}>
        { icon && (
          <span className={styles.iconHolder}>
            <img src={icon} alt="" />
          </span>
        ) }
        { textarea
          ? (
            <textarea {...props} style={inputStyle} />
            )
          : (
            <input {...props} style={inputStyle} />
            ) }
        { infoText && (
          <div className={styles.popOverHolder}>
            <Popover
              content={<p>{ infoText }</p>}
              show={isHoveringInfo}
              align="right"
            >
              <img
                src={infoIconSrc}
                alt="info"
                onMouseEnter={() => setIsHoveringInfo(true)}
                onMouseLeave={() => setIsHoveringInfo(false)}
              />
            </Popover>
          </div>
        ) }
      </div>
      { error && <label>{ error }</label> }
    </div>
  )
}

export default Field
