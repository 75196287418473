import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import ReactDOM from 'react-dom'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'
import './translations'

import 'react-toastify/dist/ReactToastify.css'
import './index.scss'

import SignupPage from './pages/signup'

axios.defaults.baseURL =
  process.env.REACT_APP_API_URL ?? 'http://localhost:9000'

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <SignupPage />
      <ToastContainer />
    </Router>
  </React.StrictMode>,
  document.getElementById('root'),
)
