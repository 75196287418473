/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useState } from 'react'
import { saveAs } from 'file-saver'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import { parceProAccountsFile } from '../../../helpers/parsing'
import { uploadImage, createProAccounts } from '../../../helpers/api'
import {
  Button,
  Checkbox,
  FileUpload,
  ImageUpload,
  Modal,
} from '../../../components'
import ProAccount from '../../../models/pro-account'

import templateImageFrSrc from '../../../assets/images/bulk-create-template-fr.png'
import templateImageEnSrc from '../../../assets/images/bulk-create-template-en.png'
// @ts-expect-error
import templateFrSrc from '../../../assets/xlsx/bulk-create-template-fr.xlsx'
// @ts-expect-error
import templateEnSrc from '../../../assets/xlsx/bulk-create-template-en.xlsx'

import styles from './styles.module.scss'

const ALLOWED_FILE_TYPES = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
]

interface ImportModalProps {
  show?: boolean
  onVisibilityChange?: (visible: boolean) => void
}

const ImportModal = (props: ImportModalProps) => {
  const { t, i18n } = useTranslation('importModal')
  const { t: generalT } = useTranslation('general')
  const { language } = i18n
  const isFrench = language.includes('fr')

  // States

  const [sheetFile, setSheetFile] = useState<File | undefined>()
  const [accounts, setAccounts] = useState<ProAccount[] | undefined>()
  const [bannerFile, setBannerFile] = useState<File | undefined>()
  const [isSubmitConfimed, setIsSubmitConfimed] = useState<boolean>(false)

  const [sheetError, setSheetError] = useState<string | undefined>()
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  // Errors

  const accountsError = !accounts ? generalT('requiredField') : undefined
  const formHasError = accountsError !== undefined

  // Hanlders

  const resetForm = () => {
    setSheetFile(undefined)
    setAccounts(undefined)
    setBannerFile(undefined)

    setIsSubmitted(false)
    setIsLoading(false)
  }

  const onClickDownloadTemplate = () => {
    const template = isFrench ? templateFrSrc : templateEnSrc
    saveAs(template, `${t('templateName')}.xlsx`)
  }

  const onSelectFile = async (file: File) => {
    setSheetFile(file)
    setSheetError(undefined)
    setAccounts(undefined)

    try {
      const accounts = await parceProAccountsFile(file)
      setAccounts(accounts)
    } catch (error) {
      const errorString =
        String(error).includes('missing_field')
          ? t('spreadsheetFieldError')
          : t('spreadsheetGenericError')

      setSheetError(errorString)
      toast.error(errorString)
    }
  }

  const onSubmit = async () => {
    setIsSubmitted(true)
    if (formHasError || !accounts) {
      toast.error(generalT('formHasError'))
      return
    }

    try {
      setIsLoading(true)

      const proUserLogoUrl = bannerFile
        ? await uploadImage(bannerFile, 'logo')
        : undefined
      const preferredLang = language.includes('fr') ? 'fr' : 'en'

      const fullAccounts = accounts.map((a) => {
        return { ...a, proUserLogoUrl, preferredLang }
      })

      await createProAccounts(fullAccounts)

      toast.success(t('thanks'))
      resetForm()
    } catch (e: any) {
      const errorMessage = e?.response?.data?.message
      if (errorMessage === 'This account already exists') {
        toast.error(generalT('serverErrorBrokersExists'), { autoClose: false })
        return
      }
      toast.error(generalT('serverError'))
    } finally {
      setIsSubmitted(false)
      setIsLoading(false)
    }
  }

  // Rendering

  const imageSrc = isFrench ? templateImageFrSrc : templateImageEnSrc

  return (
    <Modal {...props} title={t('title')}>
      <div className={styles.holder}>
        <p>{ t('subtitle') }</p>
        <h3>{ t('step1') }</h3>
        <Button onClick={onClickDownloadTemplate} reversed>
          { t('downloadTemplate') }
        </Button>

        <h3>{ t('step2') }</h3>
        <img src={imageSrc} alt="template.xlsx" />

        <div className="dualCols">
          <div>
            <h3>{ t('step3') }</h3>
            <FileUpload
              buttonText={t('uploadSheet')}
              allowedFileTypes={ALLOWED_FILE_TYPES}
              onFileSelect={onSelectFile}
              error={sheetError ?? (isSubmitted ? accountsError : undefined)}
              file={sheetFile}
              showName
            />
          </div>
          <div>
            <h3>{ t('step4') }</h3>
            <ImageUpload
              onImageReady={setBannerFile}
              finalSize={{ width: 450, height: 150 }}
              buttonText={t('uploadLogo')}
              instructions={t('uploadLogoInstructions')}
              file={bannerFile}
            />
          </div>
        </div>

        <div className={styles.submitDisclaimer}>
          <p>{ t('submitDisclaimer') }</p>
          <Checkbox
            text={t('iUnderstand')}
            onChange={setIsSubmitConfimed}
            checked={isSubmitConfimed}
          />
        </div>

        <Button
          disabled={
            !isSubmitConfimed || (isSubmitted && formHasError !== undefined)
          }
          onClick={onSubmit}
          loading={isLoading}
        >
          { t('submit') }
        </Button>
        <p className={styles.helpDisclaimer}>
          <b>
            { t('needHelp') }{ ' ' }
            <a href={t('contactLink')} target="_blank" rel="noopener noreferrer">
              { t('contactUs') }
            </a>
          </b>
        </p>
      </div>
    </Modal>
  )
}

export default ImportModal
