import footer from './footer.json'
import general from './general.json'
import importModal from './import-modal.json'
import signup from './signup.json'

const translations = {
  footer,
  general,
  importModal,
  signup,
}

export default translations
