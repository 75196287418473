import { Area, Size } from 'react-easy-crop/types'

export const extractBase64UrlFromFile = async (file: File): Promise<string> => {
  return await new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => {
      resolve(reader.result as string)
    })
    reader.readAsDataURL(file)
  })
}

export const createImage = async (url: string): Promise<HTMLImageElement> =>
  await new Promise((resolve, reject) => {
    const image = new Image()
    image.addEventListener('load', () => resolve(image))
    image.addEventListener('error', (error) => reject(error))
    image.setAttribute('crossOrigin', 'anonymous')
    image.src = url
  })

export const cropImage = async (
  srcUrl: string,
  pixelCrop: Area,
  finalSize?: Size,
  circle: boolean = false,
): Promise<File | undefined> => {
  const image = await createImage(srcUrl)
  const canvas = document.createElement('canvas')
  const context = canvas.getContext('2d')
  if (!context) return

  // Create canvas as big as crop size and position image outside of canvas to center canvas on image and apply the wanted crop
  canvas.width = pixelCrop.width
  canvas.height = pixelCrop.height
  context.drawImage(image, Math.round(-pixelCrop.x), Math.round(-pixelCrop.y))

  // If image needs to be a circle, draw one over canvas and crop to fill circle
  if (circle) {
    context.globalCompositeOperation = 'destination-in'
    context.beginPath()
    context.arc(
      canvas.width / 2,
      canvas.height / 2,
      canvas.height / 2,
      0,
      Math.PI * 2,
    )
    context.closePath()
    context.fill()
  }

  // If a final size is provided, resize image to match given size
  if (finalSize) {
    const croppedImageSrcUrl = canvas.toDataURL()

    // Set canvas width to final desired size
    canvas.width = finalSize.width
    canvas.height = finalSize.height

    // Clear context to draw resized image
    context.clearRect(0, 0, canvas.width, canvas.height)

    // Draw image once more with desired size to resize
    const croppedImage = await createImage(croppedImageSrcUrl)
    context.drawImage(croppedImage, 0, 0, canvas.width, canvas.height)
  }

  // Return image File
  return await new Promise((resolve) => {
    // First export canvas to blob
    canvas.toBlob((blob) => {
      if (!blob) return

      // Then turn blob to a png image
      const file = new File([blob], 'image', { type: 'image/png' })
      resolve(file)
    }, 'image/png')
  })
}
