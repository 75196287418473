import React, { FC } from 'react'
import { Loader } from '..'

import styles from './styles.module.scss'

interface ButtonProps {
  icon?: string
  loading?: boolean
  reversed?: boolean
}

const Button: FC<ButtonProps & any> = (props) => {
  const {
    icon,
    loading = false,
    disabled = false,
    reversed = false,
    children,
    className,
  } = props

  return (
    <button
      {...props}
      disabled={loading || disabled}
      style={{ paddingRight: icon ? 15 : undefined }}
      className={`${className ? String(className) : ''} ${reversed ? 'reversed' : ''}`}
    >
      { loading && (
        <span className={styles.loader}>
          <Loader />
        </span>
      ) }
      { children }
      { icon && (
        <span className={styles.icon}>
          <img src={icon} alt="" />
        </span>
      ) }
    </button>
  )
}

export default Button
