/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import { Size } from 'react-easy-crop/types'
import { FileUpload, ImageCropper, Popover } from '..'
import { extractBase64UrlFromFile } from '../../helpers/image'
import useWindowSize from '../../hooks/use-window-size'

import styles from './styles.module.scss'

const PREVIEW_WIDTH = 90
const ALLOWED_FILE_TYPES = ['image/jpeg', 'image/png', 'image/gif']

interface ImageUploadProps {
  cropShape?: 'rect' | 'round'
  finalSize?: Size
  error?: string
  file?: File
  buttonText: string
  instructions: string
  onImageReady?: (file: File) => void
}

const ImageUpload = ({
  finalSize = undefined,
  cropShape = 'rect',
  error,
  file,
  buttonText,
  instructions,
  onImageReady,
}: ImageUploadProps) => {
  const { width } = useWindowSize()

  // States

  const [showPopOver, setShowPopOver] = useState(false)
  const [preCropUrl, setPreCropUrl] = useState<string | undefined>()
  const [url, setUrl] = useState<string | undefined>()
  const [_error, setError] = useState<string | undefined>(error)

  // Effects

  useEffect(() => {
    setError(error)
  }, [error])

  useEffect(() => {
    if (file === undefined) {
      setPreCropUrl(undefined)
      setUrl(undefined)
      setError(undefined)
    }
  }, [file])

  useEffect(() => {
    setShowPopOver(preCropUrl !== undefined)
  }, [preCropUrl])

  useEffect(() => {
    if (!showPopOver) setPreCropUrl(undefined)
  }, [showPopOver])

  // Handlers

  const onFileSelect = async (file: File) => {
    const extractedUrl = await extractBase64UrlFromFile(file)
    setPreCropUrl(extractedUrl)
    setError(undefined)
  }

  const onCropDone = async (file: File | undefined) => {
    setPreCropUrl(undefined)
    if (!file) return

    const extractedUrl = await extractBase64UrlFromFile(file)
    setUrl(extractedUrl)
    setError(undefined)

    if (onImageReady) onImageReady(file)
  }

  // Rendering

  const aspectRatio = finalSize ? finalSize.width / finalSize.height : 1
  const previewHeight = PREVIEW_WIDTH / aspectRatio

  return (
    <div>
      <Popover
        width={width && width > 400 ? 350 : 280}
        align="left"
        content={(
          <ImageCropper
            url={preCropUrl}
            finalSize={finalSize}
            cropShape={cropShape}
            onCropDone={onCropDone}
          />
        )}
        show={showPopOver}
        onShowChange={setShowPopOver}
        hideOnClickOut
      >
        <FileUpload
          buttonText={buttonText}
          instructions={instructions}
          allowedFileTypes={ALLOWED_FILE_TYPES}
          onFileSelect={onFileSelect}
          draggingClassName={styles.dragging}
          error={_error}
        >
          <div
            style={{
              borderRadius: cropShape === 'round' ? '50%' : 5,
              backgroundImage: url ? `url("${url}")` : undefined,
              minWidth: PREVIEW_WIDTH,
              maxWidth: PREVIEW_WIDTH,
              minHeight: previewHeight,
              maxHeight: previewHeight,
            }}
            className={styles.preview}
          />
        </FileUpload>
      </Popover>
    </div>
  )
}

export default ImageUpload
