import axios from 'axios'
import ProAccount from '../models/pro-account'

type ImageType = 'logo' | 'banner'

// Function to upload an image to api
export const uploadImage = async (
  file: File,
  type: ImageType,
): Promise<string> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('folder', `broker/${type}`)

  const { data } = await axios.post('/images', formData)

  const { url } = data
  return url
}

// Function to create pro accounts in bulk
export const createProAccounts = async (
  accounts: ProAccount[],
): Promise<void> => {
  const body = { accounts }
  await axios.post('/brokers/bulk', body)
}

// Function to request a pro user email link from server
export const requestProUserLinkEmail = async (email: string): Promise<void> => {
  await axios.put('/brokers/request-link', { email })
}
