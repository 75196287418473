import ProAccount from '../models/pro-account'
import xlsx from 'xlsx'

const JSON_ACCOUNT_KEYS = {
  firstName: {
    fr: 'Prénom',
    en: 'First Name',
  },
  lastName: {
    fr: 'Nom de famille',
    en: 'Last Name',
  },
  email: {
    fr: 'Courriel',
    en: 'Email',
  },
  phoneNumber: {
    fr: 'Phone number',
    en: 'Numéro de téléphone',
  },
  agencyName: {
    fr: 'Agency name',
    en: "Nom de l'agence",
  },
  websiteUrl: {
    fr: 'Website URL',
    en: 'URL du site web',
  },
  facebookUrl: {
    fr: 'Facebook URL',
    en: 'URL de la page Facebook',
  },
  twitterUrl: {
    fr: 'Twitter URL',
    en: 'URL du compte Twitter',
  },
  youtubeUrl: {
    fr: 'Youtube URL',
    en: 'URL de la chaîne Youtube',
  },
  instagramUrl: {
    fr: 'Instagram URL',
    en: 'URL du compte Instagram',
  },
}

// Function to map an upload xlsx json formatted line into a pro account object
export const mapJsonToAccount = (line: any): ProAccount => {
  const account: ProAccount = {
    firstName: '',
    lastName: '',
    email: '',
  }

  for (const key of Object.keys(JSON_ACCOUNT_KEYS)) {
    // @ts-expect-error
    const value = line[JSON_ACCOUNT_KEYS[key].fr] || line[JSON_ACCOUNT_KEYS[key].en]
    // @ts-expect-error
    account[key] = value ? `${String(value)}` : undefined
  }

  const { email, firstName, lastName } = account
  if (
    !email ||
    email === '' ||
    !firstName ||
    firstName === '' ||
    !lastName ||
    lastName === ''
  ) { throw new Error('missing_field') }
  return account
}

// Function to parse a csv, xls or xlsx file to Pro accounts objects
export const parceProAccountsFile = async (
  file: File,
): Promise<ProAccount[]> => {
  return await new Promise((resolve, reject) => {
    const reader = new FileReader()

    const onLoad = async ({ target }: ProgressEvent<FileReader>) => {
      // eslint-disable-next-line prefer-promise-reject-errors
      if (!target) return reject('invalid_file') // should not happen
      const { result } = target

      // Convert binary string object to xlsx object
      const doc = xlsx.read(result, { type: 'binary' })
      const sheet = doc.Sheets[doc.SheetNames[0]]

      // Convert first sheet of xlsx doc to json
      const json = xlsx.utils.sheet_to_json(sheet)

      try {
        // Map json to pro accounts
        const accounts = json.map(mapJsonToAccount)
        resolve(accounts)
      } catch (error) {
        reject(error)
      }
    }

    reader.onload = onLoad
    // Convert file object to binary string
    reader.readAsBinaryString(file)
  })
}
